
import { Component, Vue } from 'vue-property-decorator'
import { LoginModel } from './models/LoginModel'
import { required, email, minLength } from 'vuelidate/lib/validators'
import { namespace } from 'vuex-class'
const AuthStore = namespace('AuthStore')

import BaseButton from '@/components/base/BaseButton.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Field from '@/components/forms/Field.vue'
import Modal from '@/components/Modal.vue'

const validations = {
  loginModel: {
    email: { required, email },
    password: { required, minLength: minLength(6) },
  },
}

@Component({
  components: { FormGroup, Field, BaseButton, Modal },
  metaInfo: {
    title: 'Вход для исполнителей',
  },
  validations,
})
export default class LoginPage extends Vue {
  private loginModel: LoginModel = new LoginModel()
  private loginError = false

  @AuthStore.Action
  private login!: (data: LoginModel) => Promise<LoginModel | any>

  async loginUser(): Promise<void | boolean> {
    this.$v.$touch()

    if (this.$v.$invalid) {
      return false
    } else {
      const result = await this.login(this.loginModel)

      if (result.status && result.status === 'success') {
        if (
          Object.prototype.hasOwnProperty.call(this.$route.query, 'redirect_to')
        ) {
          await this.$router.push('' + this.$route.query['redirect_to'])
        } else {
          await this.$router.push({ name: 'ImplementerPage' })
        }
      } else {
        this.loginError = true
      }
    }
  }
}
